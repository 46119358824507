import { Container, Box } from "@mui/material";

import SectionInfo from "../components/section/section_info";
import SectionImgInfo from "../components/section/section_img_info";
import SectionInfoImg from "../components/section/section_info_img";
import SectionIntroSale from "../components/section/section_intro_sale";
// import SectionRating from "../components/section/section_rating";
import SectionSmartContract from "../components/section/section_smart_contract";
import SectionImgInfoButton from "../components/section/section_img_info_button";
import SectionTokenomics from "../components/section/section_tokenomics";
import SectionRoadmap from "../components/section/section_roadmap";
import ContractAddressCard from "../components/element/contract_address_card";
import SectionCountdown from "../components/section/section_countdown";
import SectionFAQ from "../components/section/section_faq";
import SectionSubscribe from "../components/section/section_subscribe";

// Localization
import {
    section,
    whitepaper,
    contractAddress
} from "../utils/localization/localization_en.json";

// Asset
import {
    Section2Background,
    Section4Background,
    Section7Background,
    Section12WhitepaperBackground,
    Section20Background
} from "../utils/asset";

function Home() {
    return (
        <div>
            {/* Section 1 */}
            <SectionIntroSale />
            <Container>
                {/* Section 2 */}
                <SectionImgInfo id={"concept"} img={Section2Background} title={section["2"].title} content={section["2"].content} />
                {/* Section 4 */}
                <SectionInfoImg id={"mission"} img={Section4Background} title={section["4"].title} subtitle={section["4"].subtitle} content={section["4"].content} />
                {/* Section 7 */}
                <SectionImgInfo id={"purpose"} img={Section7Background} title={section["7"].title} subtitle={section["7"].subtitle} content={section["7"].content} />
                {/* Section 3 */}
                <SectionInfo id={"token"} title={section["3"].title} subtitle={section["3"].subtitle} />
                <ContractAddressCard address={contractAddress} />
                {/* Section 10 */}
                <SectionSmartContract title={section["10"].title} subtitle={section["10"].subtitle} content={section["10"].content} />
                {/* Section 8 */}
                {/* <SectionInfo id={"rating"} title={section["8"].title} subtitle={section["8"].subtitle} />
                <SectionRating /> */}
                {/* Section 13 */}
                <SectionTokenomics />
                {/* Section 12 */}
                <SectionImgInfoButton
                    id={"whitepaper"}
                    img={Section12WhitepaperBackground}
                    title={section["12"].whitepaper.title}
                    subtitle={section["12"].whitepaper.subtitle}
                    content={section["12"].whitepaper.content}
                    action={whitepaper.label}
                    onClick={() => window.open(whitepaper.url, "_blank")}
                />
                {/* Section 14 */}
                <SectionInfo id={"roadmap"} title={section["14"].title} subtitle={section["14"].subtitle} />
                <SectionRoadmap />
                {/* Section 15 */}
                <SectionCountdown />
                {/* Section 20 */}
                <SectionInfo id={"faq"} title={section["20"].title} subtitle={section["20"].subtitle} />
                <SectionFAQ img={Section20Background} title={section["20"].title} />
                {/* Section 21 */}
                <SectionInfo title={section["21"].title} subtitle={section["21"].subtitle} />
                <SectionSubscribe />
            </Container>
            <Box height={40} />
        </div>
    );
};

export default Home;