import { Grid, Typography } from "@mui/material";
import SubdirectoryArrowRightOutlinedIcon from '@mui/icons-material/SubdirectoryArrowRightOutlined';

export default function BranchCard({ title }) {
    const containerStyle = {
        position: "relative",
        padding: "5px 10px",
        borderRadius: 15,
        background: "hsla(0, 0%, 100%, 0.05)"
    }

    const itemStyle = {
        padding: 10
    }

    return (
        <Grid item xs={12} style={{ padding: "10px 0px", paddingLeft: "20px" }}>
            <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="start"
                style={containerStyle}
            >
                <Grid item style={itemStyle}>
                    <SubdirectoryArrowRightOutlinedIcon style={{ display: "inline", width: 25, height: 25 }} />
                </Grid>
                <Grid item xs style={itemStyle}>
                    <Typography variant="subtitle1" gutterBottom component="div" style={{ margin: "0 auto" }}>
                        {title}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}