import { Grid, Box, Typography } from "@mui/material";

export default function StepCard({ number, title, img, xs = 12, md = 4, ...style }) {
    const numberStyle = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "0 auto",
        width: "70px",
        height: "70px",
        background: "#030239",
        borderRadius: "100%"
    }

    return (
        <Grid item xs={xs} md={md}>
            <Box sx={{ width: '100%' }}>
                <div>
                    <div style={numberStyle}>
                        <Typography variant="h3" gutterBottom component="div" className={`text-gradient-${number}`} style={{ margin: 0 }}>
                            {number}
                        </Typography>
                    </div>
                </div>
                <Typography variant="h5" gutterBottom component="div" style={{ padding: "15px 0px" }}>
                    {title}
                </Typography>
            </Box>
            <Box sx={{ width: '100%' }}>
                <img src={img} alt={title} style={style.imageStyle} />
            </Box>
        </Grid>
    );
}