import { Container, useTheme, useMediaQuery } from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import RoadmapCard from "../element/roadmap_card";

// Localization
import { roadmap } from "../../utils/localization/localization_en.json";

export default function SectionRoadmap() {
  const containerStyle = {};

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Container style={containerStyle}>
      <Timeline position={matches ? "alternate" : "right"}>
        {roadmap &&
          roadmap.map((item, index) => (
            <TimelineItem key={index} sx={matches ? [] : [
              {
                "&:before": {
                  flex: 0,
                  WebkitFlex: 0,
                  padding: 0
                }
              }
            ]}
            >
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot style={{ background: "#846ff4" }} />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={matches ? [] : [{ padding: "10px 15px" }]}>
                <RoadmapCard index={index} item={item} />
              </TimelineContent>
            </TimelineItem>
          ))}
      </Timeline>
    </Container>
  );
}
