import React, { useState, useEffect } from "react";
import { Container, Grid } from "@mui/material";
import { useSelector } from 'react-redux';
import { selectStage } from "../../store/slice/stage";
import FeatureCard from "../element/feature_card";

// Localization
import {
    feature
} from "../../utils/localization/localization_en.json";

export default function SectionFeatures() {
    const containerStyle = {}

    const stage = useSelector((state) => state.stage);
    const stageId = useSelector(selectStage);

    const [current, setCurrent] = useState({ stage: 0 });

    useEffect(() => {
        if (stageId !== -1) {
            setCurrent(stage.entities[stage.ids[stageId]]);
        } else {
            setCurrent(stage.entities[stage.ids[stage.ids.length - 1]]);
        }
    }, [stage, stageId])

    const status = (value) => {
        if (value.state) return 2;
        
        if (value && current) {
            if (value.stage < current.stage) {
                return 2;
            }
            
            if (value.stage === current.stage) {
                return 1;
            }
    
            return 0;
        } else {
            return 0;
        }
    }

    return (
        <Container style={containerStyle}>
            <Grid
                container
                spacing={3}
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                {feature.map((value, index) => <FeatureCard key={index} title={value.title} status={status(value)} content={value.content} />)}
            </Grid>
        </Container>
    );
}