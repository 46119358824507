import React, { useRef, useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
import Countdown from "react-countdown";
import { requestStatus } from "../../utils/request_status";
import CountdownHolderCard from "./countdown_holder_card";

// Localization
import {
    counter
} from "../../utils/localization/localization_en.json";

const renderer = ({ days, hours, minutes, seconds }) => {
    return (
        <CountdownHolderCard days={days} hours={hours} minutes={minutes} seconds={seconds} />
    );
};

export default function CountdownCard({ status, date, current, next }) {
    const countdownRef = useRef(null);

    const boxStyle = {
        padding: "10px 20px",
        borderBottom: "3px solid #846ff4",
        borderRadius: 10,
        backgroundColor: "hsla(0, 0%, 100%, 0.05)",
        boxShadow: "0px 5px 10px 0 rgb(168 67 253 / 30%)"
    }

    const itemStyle = {
        padding: "10px 0px",
        textAlign: "center"
    }

    const labelStyle = {
        color: "hsla(0, 0%, 100%, 0.5)"
    }

    useEffect(() => {
        if (countdownRef && countdownRef.current) {
            countdownRef.current.start();
        }
    });

    return (
        <Grid item xs={12} sm={6} md={6} lg={6} style={{ minWidth: 420 }}>
            <Box sx={{ width: '100%' }} style={boxStyle}>
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item xs={12} style={itemStyle}>
                        {status === requestStatus.SUCCEEDED ? <Countdown
                            ref={countdownRef}
                            date={date}
                            renderer={renderer}
                        /> : <Countdown
                            date={Date.now()}
                            renderer={renderer}
                        />}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} style={itemStyle}>
                        <Box style={{ width: "100%" }}>
                            <Typography variant="body1" gutterBottom component="div" style={labelStyle}>
                                {counter.currentStage}
                            </Typography>
                        </Box>
                        <Box style={{ width: "100%" }}>
                            <Typography variant="subtitle1" gutterBottom component="div">
                                {current !== undefined ? current.title : ''}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} style={itemStyle}>
                        <Box style={{ width: "100%" }}>
                            <Typography variant="body1" gutterBottom component="div" style={labelStyle}>
                                {counter.nextStage}
                            </Typography>
                        </Box>
                        <Box style={{ width: "100%" }}>
                            <Typography variant="subtitle1" gutterBottom component="div">
                                {next !== undefined ? next.title : ''}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    );
}