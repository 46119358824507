import { Grid, Box, Typography } from "@mui/material";

// Localization
import {
    round,
    token,
    tokens,
} from "../../utils/localization/localization_en.json";

export default function PriceCard({ type, roundIndex, total, priceUSD, pricePair }) {
    // const totalSupply = 1000000000;

    const boxStyle = {
        padding: "20px 0px",
        border: "1px solid hsla(0, 0%, 100%, 0.25)",
        borderRadius: 10,
        backgroundColor: "hsla(0, 0%, 100%, 0.05)"
    }

    const titleStyle = {
        textAlign: "center"
    }

    const subtitleStyle = {
        color: "rgb(132, 111, 244)",
        textAlign: "center"
    }

    const typeStyle = {
        width: "90%",
        color: "#fff",
        textAlign: "center",
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20
    }

    const pricePairToToken = () => {
        return pricePair !== 0 ? parseInt(pricePair / priceUSD) : '?';
    }

    return (
        <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box sx={{ width: '100%' }} style={boxStyle}>
                <Typography variant="h5" gutterBottom component="div" style={subtitleStyle}>
                    {`${round} ${roundIndex}`}
                </Typography>
                <Typography variant="h3" gutterBottom component="div" style={titleStyle}>
                    {`${priceUSD} $`}
                </Typography>
                <Typography variant="h6" gutterBottom component="div" style={subtitleStyle}>
                    {`1 BNB ~ ${pricePairToToken()} ${token}`}
                </Typography>
                <Typography variant="h5" gutterBottom component="div" style={titleStyle}>
                    {total}
                </Typography>
                <Typography variant="h6" gutterBottom component="div" style={subtitleStyle}>
                    {tokens}
                </Typography>
                <Typography variant="h6" gutterBottom component="div" className="price-type-gradient" style={typeStyle}>
                    {type}
                </Typography>
            </Box>
        </Grid>
    );
}