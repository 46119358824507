import React, { useEffect, useState } from "react";
import { Container, Grid } from "@mui/material";
import Web3 from 'web3';
import { BigNumber } from "ethers";
import PriceCard from "../element/price_card";

// Contract
import contract from "../../utils/contract/contract.json";

// Localization
import {
    ico,
    // ido,
    contractAddress
} from "../../utils/localization/localization_en.json";

export default function SectionPrices() {
    const web3 = new Web3('https://bsc-dataseed1.binance.org');

    const [pricePair, setPricePair] = useState(0);

    const containerStyle = {}

    useEffect(() => {
        async function fetchPairPrice() {
            setPricePair(await getPairPrice());
        }

        fetchPairPrice();
        // eslint-disable-next-line
    }, [])

    const getPairPrice = async () => {
        let amountOut;

        try {
            let factor = BigNumber.from(10).pow(8);
            let oracle = await new web3.eth.Contract(contract.abi, contractAddress);
            amountOut = BigNumber.from(await oracle.methods.getLatestPrice().call());
            amountOut = amountOut.div(factor);
        } catch (error) { }

        if (!amountOut) return 0;

        return amountOut;
    }

    return (
        <Container style={containerStyle}>
            <Grid
                container
                spacing={3}
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <PriceCard type={ico} roundIndex={1} total={"100 000 000"} priceUSD={0.10} pricePair={pricePair} />
                <PriceCard type={ico} roundIndex={2} total={"100 000 000"} priceUSD={0.20} pricePair={pricePair} />
            </Grid>
        </Container>
    );
}