import { Grid, Typography } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';

export default function CheckListCard({ title }) {
    const containerStyle = {
        position: "relative",
        padding: "0px 10px 0px 0px",
        borderRadius: 10,
        background: "hsla(0, 0%, 100%, 0.05)"
    }

    const itemStyle = {
        padding: 10
    }

    const iconStyle = {
        padding: 10,
        borderRadius: 10,
        backgroundImage: "linear-gradient(135deg, hsla(0, 0%, 100%, 0.05), hsla(0, 0%, 100%, 0.1))"
    }

    return (
        <Grid item xs={12} style={{ padding: "10px 0px" }}>
            <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="start"
                style={containerStyle}
            >
                <Grid item style={{...itemStyle, ...iconStyle}}>
                    <CheckIcon style={{ width: 30, height: 30 }} />
                </Grid>
                <Grid item xs style={itemStyle}>
                    <Typography variant="subtitle1" gutterBottom component="div" style={{ margin: 0 }}>
                        {title}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}