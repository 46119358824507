import { Container, Grid } from "@mui/material";
import Block from "../element/block";
import ImageCard from "../element/image_card";

export default function SectionInfoImg({ id, img, title, subtitle, content }) {
    const containerStyle = {
        padding: "40px 0px"
    }

    return (
        <Container id={id} style={containerStyle}>
            <Grid container alignItems="center" justifyContent="center">
                <Grid item md={6} order={{ xs: 2, sm: 2, md: 1 }}>
                    <Block title={title} subtitle={subtitle} content={content} />
                </Grid>
                <ImageCard src={img} alt={title} order={{ xs: 1, sm: 1, md: 2 }} />
            </Grid>
        </Container>
    );
}