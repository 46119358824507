import { Grid } from "@mui/material";

export default function ImageCard({ src, alt, ...data }) {
    const gridStyle = {
        textAlign: "center",
        padding: 20
    }

    return (
        <Grid item md={6} alignItems="center" justifyContent="center" style={gridStyle} {...data}>
            <img src={src} alt={alt} className="responsive-image" />
        </Grid>
    );
}