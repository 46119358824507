import { Container, Grid } from "@mui/material";
import SectionInfo from "./section_info";
import TrustedCard from "../element/trusted_card";

// Localization
import {
    section
} from "../../utils/localization/localization_en.json";

export default function SectionTrusted() {
    return (
        <>
            <SectionInfo id={"trusted"} title={section["17"].title} subtitle={section["17"].subtitle} content={section["17"].content} />
            <Container>
                <Grid container alignItems="center" justifyContent="center">
                    <TrustedCard />
                </Grid>
            </Container>
        </>
    );
}