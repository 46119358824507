import { Box, Typography } from "@mui/material";

export default function BlockTransparent({ title, subtitle, content }) {
    return (
        <Box sx={{ width: '100%', maxWidth: 520 }} style={{ textAlign: "left" }}>
            <Typography variant="h4" gutterBottom component="div" style={{ opacity: 0.8 }} className="hero-section-subtitle">
                {subtitle}
            </Typography>
            <Typography variant="h2" gutterBottom component="div" style={{ textShadow: "rgba(123, 85, 217, 0.8) 4px 3px" }} className="hero-section-title" >
                {title}
            </Typography>
            <Typography variant="body1" gutterBottom  style={{ opacity: 0.8 }}>
                {content}
            </Typography>
        </Box>
    );
}