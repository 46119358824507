import { Grid, Box, Typography } from "@mui/material";

export default function StateCard({ index, icon, title, content }) {
    const containerStyle = {
        position: "relative",
        padding: "5px 10px",
        borderRadius: 10,
        background: "hsla(0, 0%, 100%, 0.05)"
    }

    const itemStyle = {
        padding: 10
    }

    const iconStyle = {
        padding: 10,
        borderRadius: 10
    }

    const contentStyle = {
        color: "hsla(0, 0%, 100%, 0.5)"
    }

    return (
        <Grid item xs={12} style={{ padding: "10px 0px" }}>
            <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="start"
                style={containerStyle}
            >
                <Grid item className={`state-gradient-${index}`} style={{...itemStyle, ...iconStyle}}>
                    {icon}
                </Grid>
                <Grid item xs style={itemStyle}>
                    <Box style={{ width: "100%" }}>
                        <Typography variant="subtitle1" gutterBottom component="div">
                            {title}
                        </Typography>
                    </Box>
                    <Box style={{ width: "100%" }}>
                        <Typography variant="body1" gutterBottom component="div" style={contentStyle}>
                            {content}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
}