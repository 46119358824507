import { Container, Grid, Box, Typography } from "@mui/material";

export default function SectionIntro({ title }) {
    const gridStyle = {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }

    const containerStyle = {
        zIndex: 2,
        paddingTop: "100px"
    }

    return (
        <div style={gridStyle}>
            <canvas className="gradient-canvas" />
            <Container style={containerStyle}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={12}>
                        <div style={{ margin: "0 auto", width: '100%', maxWidth: 520 }}>
                            <Box sx={{ width: '100%', maxWidth: 520 }} style={{ textAlign: "center" }}>
                                <Typography variant="h2" gutterBottom component="div" style={{ textShadow: "rgba(123, 85, 217, 0.8) 4px 3px" }}>
                                    {title}
                                </Typography>
                            </Box>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}