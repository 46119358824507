import { Grid, Box, Typography } from "@mui/material";

// Localization
import {
    warrantybox,
    authorizedWarrantyProvider,
    logo,
    trustedBy
} from "../../utils/localization/localization_en.json";

// Asset
import {
    Logo
} from "../../utils/asset";

export default function TrustedCard() {
    const containerStyle = {
        position: "relative",
        padding: "5px 10px",
        borderRadius: 10,
        backgroundImage: "linear-gradient(135deg, #2b2f75, #1a1a53)",
        textAlign: "center"
    }

    const itemStyle = {
        padding: 10
    }

    const iconStyle = {
        width: 80
    }

    const contentStyle = {
        color: "hsla(0, 0%, 100%, 0.5)"
    }

    const logoStyle = {
        backgroundColor: "#030239",
        padding: "15px 10px",
        borderRadius: "10px"
    }

    return (
        <Grid item xs={12} sm={10} md={7} lg={6} style={{ maxWidth: 560, padding: "10px 0px" }}>
            <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="start"
                style={containerStyle}
            >
                <Grid item style={itemStyle}>
                    <img src={Logo} alt={warrantybox} style={iconStyle} />
                </Grid>
                <Grid item xs style={itemStyle}>
                    <Box style={{ width: "100%" }}>
                        <Typography variant="subtitle1" gutterBottom component="div">
                            {authorizedWarrantyProvider}
                        </Typography>
                    </Box>
                    <Box style={{ width: "100%" }}>
                        <Typography variant="body1" gutterBottom component="div" style={contentStyle}>
                            {trustedBy}
                        </Typography>
                    </Box>
                    <Box style={{ width: "100%" }}>
                        <Typography variant="h6" gutterBottom component="div">
                            {warrantybox}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item style={itemStyle}>
                    <Box style={{ width: "100%" }}>
                        <Typography variant="h6" gutterBottom component="div" style={{ ...contentStyle, ...logoStyle }}>
                            {logo}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
}