import { Grid, Box, Typography, Divider } from "@mui/material";
import CheckListOutlinedCard from "../element/check_list_outlined_card";

// Localization
import { stage } from "../../utils/localization/localization_en.json";

export default function RoadmapCard({ index, item }) {
    const boxStyle = {
        padding: "10px 20px",
        borderBottom: "3px solid #846ff4",
        borderRadius: 10,
        backgroundColor: "hsla(0, 0%, 100%, 0.05)",
        boxShadow: "0px 5px 10px 0 rgb(168 67 253 / 30%)",
        textAlign: "left"
    }


    return (
        <Grid item xs={12}>
            <Box sx={{ width: '100%' }} style={boxStyle} data-aos="fade-up" data-aos-anchor-placement="top-center">
                {
                    item.planned ? (
                        <Typography variant="h6" gutterBottom component="div" >
                            {item.dayMonth}
                        </Typography>
                    ) : (
                        <></>
                    )
                }
                <Typography variant="h5" gutterBottom component="div" >
                    {item.year}
                </Typography>
                <Divider style={{ marginBottom: "0.35em" }} />
                <Typography variant="h4" gutterBottom component="div" className="text-gradient">
                    {stage} {index + 1}
                </Typography>
                <Typography variant="h5" gutterBottom component="div" >
                    {item.title}
                </Typography>
                {item.checkList && item.checkList.map((item, key) => (
                    <CheckListOutlinedCard key={key} state={item.state} title={item.title} />
                ))}
            </Box>
        </Grid>
    );
}