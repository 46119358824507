import { Container, Grid } from "@mui/material";
import ImageCard from "../element/image_card";
import Block from "../element/block";

export default function SectionImgInfo({ id, img, title, subtitle, content }) {
    const containerStyle = {
        padding: "40px 0px"
    }

    return (
        <Container id={id} style={containerStyle}>
            <Grid container alignItems="center" justifyContent="center">
                <ImageCard src={img} alt={title} />
                <Grid item md={6}>
                    <Block title={title} subtitle={subtitle} content={content} />
                </Grid>
            </Grid>
        </Container>
    );
}