import { Box, Typography } from "@mui/material";

export default function Block({ title, subtitle, content, style }) {
    const contentStyle = {
        color: "rgba(255, 255, 255, 0.7)"
    }

    return (
        <Box sx={{ width: '100%', maxWidth: 500 }} style={style}>
            <Typography variant="h4" gutterBottom component="div" className="text-gradient" data-aos="fade-up" data-aos-anchor-placement="top-center">
                {subtitle}
            </Typography>
            <Typography variant="h2" gutterBottom component="div" data-aos="fade-up" data-aos-anchor-placement="top-center">
                {title}
            </Typography>
            <Typography variant="body1" gutterBottom component="div" style={contentStyle}>
                {content}
            </Typography>
        </Box>
    );
}