import React from "react";
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Button } from "@mui/material";

const arrowStyle = {
    width: 35,
    height: 35
};

export const PrevButton = ({ enabled, onClick }) => (
    <Button
        variant="text"
        className="embla__button embla__button--prev"
        onClick={onClick}
        disabled={!enabled}
        style={{ marginTop: 15 }}
    >
        <ArrowBackIos style={arrowStyle} />
    </Button>
);

export const NextButton = ({ enabled, onClick }) => (
    <Button
        variant="text"
        className="embla__button embla__button--next"
        onClick={onClick}
        disabled={!enabled}
        style={{ marginTop: 15 }}
    >
        <ArrowForwardIos style={arrowStyle} />
    </Button>
);