import { Container, Grid } from "@mui/material";
import SectionInfo from "./section_info";
import SectionInfoInfoInfo from "./section_info_info_info";
import SectionDistribution from "./section_distribution";
import SectionPrices from "./section_prices";
import RootCard from "../element/root_card";
import BranchCard from "../element/branch_card";

// Localization
import {
    section,
    tokenomics,
    liquidity,
    supply,
    tax,
    taxes
} from "../../utils/localization/localization_en.json";

export default function SectionTokenomics() {
    const containerStyle = {
        padding: "40px 0px"
    }

    return (
        <Container style={containerStyle}>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <SectionInfoInfoInfo
                    id={"tokenomics"}
                    title={tokenomics}
                    fTitle={'85 %'}
                    fSubtitle={liquidity}
                    sTitle={'15 %'}
                    sSubtitle={tax}
                    tTitle={"1 B"}
                    tSubtitle={supply}
                />
                <SectionInfo id={"distribution"} title={section["13"].title} subtitle={section["13"].subtitle} />
                <SectionDistribution />
                <SectionInfo id={"prices"} title={section["12"].title} subtitle={section["12"].subtitle} />
                <SectionPrices />
                <SectionInfo id={"tax"} title={tax} />
                {taxes.map((value, index) => {
                    if (value.branches.length) {
                        let components = [];
                        components.push(<RootCard key={`root_${index}`} index={index + 1} percentage={value.percentage} title={value.title} />);
                        value.branches.map((branch, branchIndex) => components.push(<BranchCard key={`branch_${branchIndex}`} title={branch} />));

                        return components;
                    } else {
                        return <RootCard key={`root_${index}`} index={index + 1} percentage={value.percentage} title={value.title} />;
                    }
                })}
            </Grid>
        </Container>
    );
}