import { Grid, Box, Typography } from "@mui/material";
import LocalAtmIcon from '@mui/icons-material/LocalAtm';

export default function RootCard({ index, title, percentage }) {
    const containerStyle = {
        position: "relative",
        padding: "5px 10px",
        borderRadius: 10,
        background: "hsla(0, 0%, 100%, 0.05)"
    }

    const itemStyle = {
        padding: 10
    }

    const iconContainerStyle = {
        padding: 10,
        borderRadius: 10
    }

    const iconStyle = {
        width: 40,
        height: 40
    }

    const contentStyle = {
        color: "hsla(0, 0%, 100%, 0.5)"
    }

    return (
        <Grid item xs={12} style={{ padding: "10px 0px" }}>
            <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="start"
                style={containerStyle}
            >
                <Grid item className={`tax-gradient-${index}`} style={{ ...itemStyle, ...iconContainerStyle }}>
                    <LocalAtmIcon style={iconStyle} />
                </Grid>
                <Grid item xs style={itemStyle}>
                    <Box>
                        <Typography variant="h4" gutterBottom component="div" style={{ margin: 0, paddingLeft: 15 }}>
                            {percentage} %
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" gutterBottom component="div" style={{ ...contentStyle, margin: 0, paddingLeft: 15}}>
                            {title}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
}