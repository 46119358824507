import { Container, Grid } from "@mui/material";
import Block from "../element/block";

export default function SectionInfoPresentation({ id, link, title, subtitle, content }) {
    const containerStyle = {
        padding: "40px 0px"
    }

    const presentationStyle = {
        width: "100%",
        maxWidth: 560,
        height: 315
    }

    return (
        <Container id={id} style={containerStyle}>
            <Grid container alignItems="center" justifyContent="center">
                <Grid item md={6}>
                    <Block title={title} subtitle={subtitle} content={content} />
                </Grid>
                <Grid item md={6}>
                    <iframe
                        title={title}
                        src={link}
                        allow="autoplay; fullscreen" 
                        style={presentationStyle}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}