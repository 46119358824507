import { Container, Grid } from "@mui/material";
import Block from "../element/block";

export default function SectionInfo({ id, title, subtitle, content }) {
    const containerStyle = {
        padding: "40px 0px",
        textAlign: "center"
    }

    return (
        <Container id={id} style={containerStyle}>
            <Grid container alignItems="center" justifyContent="center">
                <Block title={title} subtitle={subtitle} content={content} />
            </Grid>
        </Container>
    );
}