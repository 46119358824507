import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useSelector } from 'react-redux';
import { selectStage } from "../../store/slice/stage";
import SectionInfo from "./section_info";
import CountdownCard from "../element/countdown_card";
import FeatureShortCard from "../element/feature_short_card";

// Localization
import {
    section,
    counter
} from "../../utils/localization/localization_en.json";

export default function SectionCountdown() {
    const stage = useSelector((state) => state.stage);
    const stageId = useSelector(selectStage);

    const [date, setDate] = useState(Date.now());
    const [current, setCurrent] = useState({ title: '', checkList: [] });
    const [next, setNext] = useState({ title: '', checkList: [] });

    useEffect(() => {
        if (stageId !== -1) {
            setDate(new Date(stage.ids[stageId]));
            setCurrent(stage.entities[stage.ids[stageId]]);

            if ((stage.ids.length - 1) > stageId) {
                setNext(stage.entities[stage.ids[stageId + 1]]);
            } else {
                setNext(stage.entities[stage.ids[stageId]]);
            }
        } else {
            setCurrent(stage.entities[stage.ids[stage.ids.length - 1]]);
            setNext(stage.entities[stage.ids[stage.ids.length - 1]]);
        }
    }, [stage, stageId])

    return (
        <>
            <SectionInfo title={section["15"].title} subtitle={section["15"].subtitle} />
            <Grid
                container
                spacing={3}
                direction="row"
                justifyContent="center"
                alignItems="start"
            >
                <CountdownCard status={stage.status} date={date} current={current} next={next} />
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography variant="h6" gutterBottom component="div" style={{ textAlign: "center" }}>
                        {counter.upcoming}
                    </Typography>
                    <Grid
                        container
                        direction="row"
                        justifyContent="start"
                        alignItems="start"
                    >
                        {current && current.checkList.map((item, key) => <FeatureShortCard key={key} state={item.state} title={item.title} />)}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}