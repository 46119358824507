import React, { useRef, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import Countdown from "react-countdown";
import { requestStatus } from "../../utils/request_status";

// Localization
import {
    clock,
    counter
} from "../../utils/localization/localization_en.json";

const renderer = ({ days, hours, minutes, seconds }) => {
    return (
        <Box className="intro-section-countdown-hour-counter">
            {days} {clock.d} {hours} {clock.h} {minutes} {clock.m} {seconds} {clock.s}
        </Box>
    );
};

export default function CountdownLineCard({ status, date, current }) {
    const countdownRef = useRef(null);

    useEffect(() => {
        if (countdownRef && countdownRef.current) {
            countdownRef.current.start();
        }
    });

    return (
        <div className="intro-section-countdown-container">
            <Typography variant="body1" gutterBottom component="div">
                {counter.upcoming}
            </Typography>
            <Typography variant="subtitle1" gutterBottom component="div">
                {current !== undefined ? current.title : ''}
            </Typography>
            {status === requestStatus.SUCCEEDED ? <Countdown
                ref={countdownRef}
                date={date}
                renderer={renderer}
            /> : <Countdown
                date={Date.now()}
                renderer={renderer}
            />}
        </div>
    );
}