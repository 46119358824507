import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useSelector } from 'react-redux';
import { selectStage } from "../../store/slice/stage";
import CountdownLineCard from "../element/countdown_line_card";

export default function SectionCountdownLine() {
    const stage = useSelector((state) => state.stage);
    const stageId = useSelector(selectStage);

    const [date, setDate] = useState(Date.now());
    const [current, setCurrent] = useState({ title: '', checkList: [] });

    useEffect(() => {
        if (stageId !== -1) {
            setDate(new Date(stage.ids[stageId]));
            setCurrent(stage.entities[stage.ids[stageId]]);
        } else {
            setCurrent(stage.entities[stage.ids[stage.ids.length - 1]]);
        }
    }, [stage, stageId])

    return (
        <Box sx={{ width: '100%', maxWidth: 520 }} style={{ textAlign: "left" }}>
            <CountdownLineCard status={stage.status} date={date} current={current} />
        </Box>
    );
}