import { Container, Grid, Box, Divider, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';

// Localization
import {
    warrantybox,
    content,
    copyright,
    links,
    followUs,
    contactUs,
    navigation,
    twitterUrl,
    telegramUrl
} from "../../utils/localization/localization_en.json";

// Asset
import { Logo } from "../../utils/asset";

export default function Footer() {
    const date = new Date();

    const footerStyle = {
        width: "100%",
        backgroundColor: "#192057"
    }

    const containerStyle = {
        color: "hsla(0, 0%, 100%, 0.5)"
    }

    const gridStyle = {
        position: "relative",
        padding: "5px 10px"
    }

    const itemStyle = {
        padding: 10
    }

    const iconStyle = {
        width: 50
    }

    const titleStyle = {
        color: "hsla(0, 0%, 100%, 0.8)",
    }

    const iconLinkStyle = {
        margin: 5,
        cursor: "pointer"
    }

    const linkStyle = {
        cursor: "pointer"
    }

    const copyRightStyle = {
        width: "100%",
        textAlign: "center"
    }

    // Route
    const { pathname } = useLocation();

    // Handle redirection
    const followHref = (path, target) => {
        if (pathname === path) {
            scrollToTargetAdjusted(target);
        } else {
            window.location.replace(`${path}?target=${target}`);
        }
    };

    function openInNewTab(url) {
        window.open(url, '_blank').focus();
    }

    function scrollToTargetAdjusted(id) {
        let elementOffset = document.getElementById(id).offsetTop;
        let headerOffset = document.getElementById("header").clientHeight;
        let offsetPosition = elementOffset - headerOffset;

        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
        });
    }

    const LinkCard = ({ title, onClick }) => {
        return (
            <Box style={{ width: "100%" }}>
                <Typography variant="body1" gutterBottom component="div" style={linkStyle} onClick={onClick}>
                    {title}
                </Typography>
            </Box>
        );
    }

    return (
        <footer style={footerStyle}>
            <Container style={containerStyle}>
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="start"
                    style={gridStyle}
                >
                    <Grid item xs={12} sm={12} md={4} lg={4} style={itemStyle}>
                        <Box style={{ width: "100%", padding: "10px 0px" }}>
                            <img src={Logo} alt={warrantybox} style={iconStyle} />
                        </Box>
                        <Box style={{ width: "100%", padding: "10px 0px" }}>
                            <Typography variant="body1" gutterBottom component="div">
                                {content}
                            </Typography>
                        </Box>
                        <Box style={{ width: "100%" }}>
                            <TwitterIcon style={iconLinkStyle} onClick={() => openInNewTab(twitterUrl)} />
                            <TelegramIcon style={iconLinkStyle} onClick={() => openInNewTab(telegramUrl)} />
                        </Box>
                    </Grid>
                    <Grid item xs style={itemStyle}>
                        <Grid
                            container
                            direction="row"
                            alignItems="start"
                            justifyContent="center"
                        >
                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <Box style={{ width: "100%" }}>
                                    <Typography variant="h6" gutterBottom component="div" style={titleStyle}>
                                        {links}
                                    </Typography>
                                </Box>
                                <LinkCard title={navigation.home} onClick={() => followHref("/", "home")} />
                                <LinkCard title={navigation.products.title} onClick={() => followHref("/products", "individual")} />
                                <LinkCard title={navigation.whitepaper} onClick={() => followHref("/", "whitepaper")} />
                                <LinkCard title={navigation.roadmap} onClick={() => followHref("/", "roadmap")} />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <Box style={{ width: "100%" }}>
                                    <Typography variant="h6" gutterBottom component="div" style={titleStyle}>
                                        {followUs}
                                    </Typography>
                                </Box>
                                <LinkCard title={"Twitter"} onClick={() => openInNewTab(twitterUrl)} />
                                <LinkCard title={"Telegram"} onClick={() => openInNewTab(telegramUrl)} />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <Box style={{ width: "100%" }}>
                                    <Typography variant="h6" gutterBottom component="div" style={titleStyle}>
                                        {contactUs}
                                    </Typography>
                                </Box>
                                <Box style={{ width: "100%" }}>
                                    <Typography variant="body1" gutterBottom component="div">
                                        chain@warrantybox.io
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Box style={copyRightStyle}>
                    <Divider />
                    <Typography variant="subtitle1" gutterBottom component="div" style={{ padding: "10px 0px" }}>
                        {`© Warrantybox.io ${date.getFullYear()} ${copyright}`}
                    </Typography>
                </Box>
            </Container>
        </footer>
    );
}