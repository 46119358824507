import { Container, Box } from "@mui/material";

import SectionInfo from "../components/section/section_info";
import SectionInfoImg from "../components/section/section_info_img";
import SectionIntro from "../components/section/section_intro";
import Section3Steps from "../components/section/section_3_steps";
import Section5Steps from "../components/section/section_5_steps";
import SectionFeatures from "../components/section/section_features";
import SectionTrusted from "../components/section/section_trusted";
import SectionCheckList from "../components/section/section_check_list";
import SectionInfoPresentation from "../components/section/section_info_presentation";
import SectionSubscribe from "../components/section/section_subscribe";

// Localization
import {
    section,
    benefit,
    navigation
} from "../utils/localization/localization_en.json";

// Asset
import {
    Section6Background,
    Section11Background,
} from "../utils/asset";

function Products() {
    return (
        <div>
            {/* Section 0 */}
            <SectionIntro title={navigation.products.title} />
            <Container>
                {/* Section 5 */}
                <SectionInfo id={"individual"} title={section["5"].title} subtitle={section["5"].subtitle} content={section["5"].content} />
                <Section3Steps />
                {/* Section 6 */}
                <SectionInfoImg id={"enterprise"} img={Section6Background} title={section["6"].title} subtitle={section["6"].subtitle} content={section["6"].content} />
                <Section5Steps />
                {/* Section 9 */}
                <SectionInfo id={"features"} title={section["9"].title} subtitle={section["9"].subtitle} />
                <SectionFeatures />
                {/* Section 17 */}
                <SectionTrusted />
                {/* Section 11 */}
                <SectionInfo id={"benefits"} title={section["11"].title} subtitle={section["11"].subtitle} />
                <SectionCheckList
                    img={Section11Background}
                    title={section["11"].title}
                    data={benefit}
                />
                {/* Section 12 */}
                <SectionInfoPresentation id={"presentation"} link={section["12"].presentation.link} title={section["12"].presentation.title} subtitle={section["12"].presentation.subtitle} />
                {/* Section 21 */}
                <SectionInfo title={section["21"].title} subtitle={section["21"].subtitle} />
                <SectionSubscribe />
            </Container>
            <Box height={40} />
        </div>
    );
};

export default Products;