import { Container, Grid } from "@mui/material";
import Block from "../element/block";

export default function SectionInfoInfoInfo({ id, title, subtitle, content, fTitle, fSubtitle, fContent, sTitle, sSubtitle, sContent, tTitle, tSubtitle, tContent }) {
    const containerStyle = {
        padding: "40px 0px"
    }

    const blockStyle = {
        textAlign: "center"
    }

    return (
        <Container id={id} style={containerStyle}>
            <Grid container alignItems="center" justifyContent="center">
                <Grid container item alignItems="center" justifyContent="center">
                    <Block title={title} subtitle={subtitle} content={content} style={blockStyle} />
                </Grid>
                <Grid container item alignItems="center" justifyContent="center" sm={12} md={4}>
                    <Block title={fTitle} subtitle={fSubtitle} content={fContent} style={blockStyle} />
                </Grid>
                <Grid container item alignItems="center" justifyContent="center" sm={12} md={4}>
                    <Block title={sTitle} subtitle={sSubtitle} content={sContent} style={blockStyle} />
                </Grid>
                <Grid container item alignItems="center" justifyContent="center" sm={12} md={4}>
                    <Block title={tTitle} subtitle={tSubtitle} content={tContent} style={blockStyle} />
                </Grid>
            </Grid>
        </Container>
    );
}