import React, { useState, useEffect, useCallback } from "react";
import { Container } from "@mui/material";
import useEmblaCarousel from 'embla-carousel-react'
import StepCard from "../element/step_card";
import { PrevButton, NextButton } from "../carousel/carousel_button";

import "../carousel/carousel.scss";

// Localization
import {
    clientStep
} from "../../utils/localization/localization_en.json";

// Asset
import { 
    PhoneSignIn, 
    PhoneScan, 
    PhoneManage 
} from "../../utils/asset";

export default function Section3Steps() {
    const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true, skipSnaps: false, speed: 5 });
    const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
    const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

    const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
    const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);

    const onSelect = useCallback(() => {
        if (!emblaApi) return;
        setPrevBtnEnabled(emblaApi.canScrollPrev());
        setNextBtnEnabled(emblaApi.canScrollNext());
    }, [emblaApi]);

    useEffect(() => {
        if (!emblaApi) return;
        emblaApi.on("select", onSelect);
        onSelect();
    }, [emblaApi, onSelect]);

    const containerStyle = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "40px 0px",
        borderRadius: "20px",
        textAlign: "center",
        background: "linear-gradient(rgb(45 44 124), rgb(8 13 56))"
    }

    const imageStyle = { 
        width: "100%",
        maxWidth: 260,
        maxHeight: 571 
    }

    return (
        <Container style={containerStyle}>
            <div className="embla" ref={emblaRef}>
                <div className="embla__container">
                    <div className="embla__slide">
                        <StepCard number={1} title={clientStep.signIn} img={PhoneSignIn} md={12} imageStyle={imageStyle} />
                    </div>
                    <div className="embla__slide">
                        <StepCard number={2} title={clientStep.grandOwnership} img={PhoneScan} md={12} imageStyle={imageStyle} />
                    </div>
                    <div className="embla__slide">
                        <StepCard number={3} title={clientStep.manageExercise} img={PhoneManage} md={12} imageStyle={imageStyle} />
                    </div>
                </div>
                <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
                <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
            </div>
        </Container>
    );
}