import SectionSwap from "../components/web3/section_swap";

function Invest() {
    const gridStyle = {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh"
    }

    return (
        <div style={gridStyle}>
            <canvas className="gradient-canvas" />
            <SectionSwap />
        </div>
    );
}

export default Invest;