import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

// AOS
import AOS from "aos";
import "aos/dist/aos.css";

// Redux
import { Provider } from "react-redux";
import store from "./store/store";
import { fetchStages } from "./store/slice/stage";

// Style
import './App.css';

// Animation
import "./components/animation/gradient_animation.scss";
import GradientAnimation from "./components/animation/gradient_animation";

// Component
import Header from "./components/navbar/header";
import Footer from "./components/navbar/footer";
import Home from "./pages/home";
import Products from "./pages/products";
import Invest from "./pages/invest";
import Policy from "./pages/policy";
import Terms from "./pages/terms";
import StayTuned from "./pages/stay_tuned";
import NoMatch from "./pages/no_match";

function App() {
  useEffect(() => {
    store.dispatch(fetchStages());
    GradientAnimation();
    AOS.init({
      once: true,
      duration: 1000
    });
    window.addEventListener('load', AOS.refresh);
    document.addEventListener("DOMContentLoaded", AOS.refresh);
    return () => {
      window.removeEventListener('load', AOS.refresh);
      document.removeEventListener("DOMContentLoaded", AOS.refresh);
    }
  });

  return (
    <div className="App">
      <Provider store={store}>
        <Router>
          <Header />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/products" element={<Products />} />
            <Route path="/invest" element={<Invest />} />
            <Route path="/policy" element={<Policy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/campaigns" element={<StayTuned />} />
            <Route path="*" element={<NoMatch />} />
          </Routes>
          <Footer />
        </Router>
      </Provider>
    </div>
  );
}

export default App;
