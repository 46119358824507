import { Grid, Box, Typography } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';

export default function CheckListOutlinedCard({ state, title }) {
    const containerStyle = {
        position: "relative",
        padding: "0px 10px 0px 0px",
        borderRadius: 10
    }

    const itemStyle = {
        padding: "0px 10px"
    }

    const iconStyle = {
        padding: "8px 10px",
        border: "2px solid hsla(0, 0%, 100%, 0.5)",
        borderRadius: 10
    }

    return (
        <Grid item xs={12} style={{ padding: "5px 0px" }}>
            <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="start"
                style={containerStyle}
            >
                <Grid item style={{...itemStyle, ...iconStyle}} className="box-shadow-effect">
                    { state ? <CheckIcon style={{ width: 20, height: 20 }}  /> : <Box width={20} height={20} /> }
                </Grid>
                <Grid item xs style={itemStyle}>
                    <Typography variant="subtitle1" gutterBottom component="div" style={{ margin: 0 }}>
                        {title}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}