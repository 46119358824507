import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
    Grid,
    Card,
    CardContent,
    Collapse,
    IconButton,
    Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Localization
import {
    showMore
} from "../../utils/localization/localization_en.json";

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
    }),
}));

export default function FaqCard({ question, answer }) {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const cardtyle = {
        width: "100%",
        padding: "5px 10px",
        borderRadius: 10,
        backgroundColor: "hsla(0, 0%, 100%, 0.05)",
        cursor: "pointer"
    }

    const questionStyle = {
        color: "hsla(0, 0%, 100%, 0.5)"
    }

    const answerStyle = {
        color: "hsla(0, 0%, 100%, 0.8)"
    }

    return (
        <Grid item xs={12}>
            <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="start"
            >
                <Card style={cardtyle} onClick={handleExpandClick} >
                    <CardContent style={{ padding: "0 16px" }}>
                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent="start"
                        >
                            <Grid item xs>
                                <Typography variant="subtitle1" style={questionStyle}>
                                    {question}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <ExpandMore
                                    expand={expanded}
                                    aria-expanded={expanded}
                                    aria-label={showMore}
                                    style={questionStyle}
                                >
                                    <ExpandMoreIcon />
                                </ExpandMore>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <CardContent>
                            <Typography variant="body1" style={answerStyle}>
                                {answer}
                            </Typography>
                        </CardContent>
                    </Collapse>
                </Card>
            </Grid>
        </Grid>
    );
}
