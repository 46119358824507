import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const useStyles = makeStyles({
    button: {
        color: "#fff!important",
        fontSize: "16px!important",
        padding: "5px 30px!important",
        borderBottom: "3px solid #846ff4!important",
        borderRadius: "10px!important",
        backgroundImage: "linear-gradient(90deg, #4ebeec, #7450fe)!important",
        boxShadow: "0px 5px 10px 0 rgb(168 67 253 / 30%)!important",
        transition: "all .8s!important",
        "&:hover": {
            borderColor: "#4ebeec!important"
        },
        "&:disabled": {
            color: "#000!important",
            backgroundImage: "linear-gradient(90deg, #d5d5d5, #e7e7e7)!important"
        }
    }
});

export default function BlockButton({ title, onClick, disabled = false }) {
    const classes = useStyles();

    return (
        <Button variant="text" onClick={onClick} className={classes.button} disabled={disabled}>
            {title}
            <ArrowForwardIosIcon style={{ width: "20px", height: "20px" }} />
        </Button>
    );
}