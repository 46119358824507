import { Container, Grid } from "@mui/material";
import ImageCard from "../element/image_card";
import FaqCard from "../element/faq_card";

// Localization
import {
    faq
} from "../../utils/localization/localization_en.json";

export default function SectionFAQ({ img, title }) {
    const containerStyle = {
        padding: "40px 0px"
    }

    return (
        <Container style={containerStyle}>
            <Grid container alignItems="center" justifyContent="center">
                <ImageCard src={img} alt={title} />
                <Grid item md={6}>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="center"
                        justifyContent="start"
                    >
                        {faq.map((value, index) => <FaqCard key={index} question={value.question} answer={value.answer} />)}
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}