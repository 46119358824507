import { Container, Grid, Box } from "@mui/material";
import ImageCard from "../element/image_card";
import Block from "../element/block";
import BlockButton from "../element/block_button";

export default function SectionImgInfoButton({ id, img, title, subtitle, content, action, onClick }) {
    const containerStyle = {
        padding: "40px 0px"
    }

    return (
        <Container id={id} style={containerStyle}>
            <Grid container alignItems="center" justifyContent="center">
                <ImageCard src={img} alt={title} />
                <Grid item md={6}>
                    <Block title={title} subtitle={subtitle} content={content} />
                    <Box height={15} />
                    <BlockButton title={action} onClick={onClick} />
                </Grid>
            </Grid>
        </Container>
    );
}