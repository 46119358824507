import { Grid, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    button: {
        width: "100%",
        color: "#fff!important",
        fontSize: "16px!important",
        cursor: "pointer",
        userSelect: "none"
    }
});

export default function SwapButton({ title, onClick, disabled = false }) {
    const classes = useStyles();

    const containerStyle = {
        margin: "10px 0px"
    }

    return (
        <Grid item xs={12} style={containerStyle}>
            <Box variant="text" onClick={onClick} className={classes.button} disabled={disabled}>
                {title}
            </Box>
        </Grid>
    );
}