import { Grid, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    button: {
        width: "100%",
        color: "#fff!important",
        fontSize: "18px!important",
        padding: "15px 20px!important",
        border: "0!important",
        borderRadius: "15px!important",
        backgroundColor: "hsla(0, 0%, 100%, 0.1)!important",
        transition: "all .8s!important",
        "&:hover": {
            backgroundColor: "hsla(0, 0%, 100%, 0.2)!important",
        },
        "&:disabled": {
            color: "#000!important",
            backgroundColor: "hsla(0, 0%, 100%, 0.05)!important",
        }
    }
});

export default function SwapButton({ title, onClick, disabled = false }) {
    const classes = useStyles();

    const containerStyle = {
        margin: "10px 0px"
    }

    return (
        <Grid item xs={12} style={containerStyle}>
            <Button variant="text" onClick={onClick} className={classes.button} disabled={disabled}>
                {title}
            </Button>
        </Grid>
    );
}