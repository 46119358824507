import { Container, Grid, Card, Typography } from "@mui/material";

export default function ContractAddressCard({ address }) {
    const containerStyle = {
        padding: "0px 0px 20px 0px",
        textAlign: "center"
    }

    const cardStyle = {
        padding: "10px 20px",
        borderBottom: "3px solid #846ff4",
        borderRadius: 10,
        backgroundColor: "hsla(0, 0%, 100%, 0.05)",
        boxShadow: "0px 5px 10px 0 rgb(168 67 253 / 30%)"
    }

    const textStyle = {
        margin: "0 auto",
        cursor: "pointer"
    }

    return (
        <Container style={containerStyle}>
            <Grid container alignItems="center" justifyContent="center">
                <Card style={cardStyle}>
                    <Typography variant="h4" gutterBottom component="div" className="text-gradient" style={textStyle} onClick={
                        () => window.open(`https://bscscan.com/address/${address}`, "_blank")
                    }>
                        {address}
                    </Typography>
                </Card>
            </Grid>
        </Container>
    );
}