import React, { createContext, useState, useEffect } from "react";
// import Web3 from "web3";
import Web3Modal from "web3modal";
import { Contract } from "@ethersproject/contracts";
import { Web3Provider } from "@ethersproject/providers";
import { parseEther, formatEther } from "@ethersproject/units";
import { BigNumber } from "ethers";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { Snackbar, Alert, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

// Contract
import contract from "../../utils/contract/contract.json";
import { contractAddress, swap } from "../../utils/localization/localization_en.json";

// Logo
const _contractSymbol = "WB";
const _contractDecimal = 18;
const _contractLogo = "https://cdn.warrantybox.io/icon/wb.png";

// Response
const _responseStatus = ["warning", "success", "error"];

// Style
const useStyles = makeStyles((theme) => ({
    alert: {
        '& MuiAlert-message': {
            color: "#000"
        },
        '& MuiAlert-alert': {
            color: "#000"
        },
        '& div': {
            color: "#000"
        }
    }
}));

// Providers
const _providerOptions = {
    walletconnect: {
        package: WalletConnectProvider,
        options: {
            infuraId: "https://mainnet.infura.io/v3/4ceeafa3e17d41f580cc9b71a08656c1"
        }
    }
}

export const Web3Context = createContext({});

const Web3Wrapper = (props) => {
    const classes = useStyles();

    // Web3
    const [state, setState] = useState(0);
    const [web3Modal, setWeb3Modal] = useState(null);
    const [provider, setProvider] = useState(null);
    const [web3Provider, setWeb3Provider] = useState(null);

    useEffect(() => {
        function web3ModalConnect() {
            if (web3Modal.cachedProvider && state === 0) {
                onConnect();
            }
        }

        if (web3Modal === null) {
            setWeb3Modal(
                new Web3Modal({
                    network: "mainnet",
                    cacheProvider: true,
                    theme: "dark",
                    providerOptions: _providerOptions
                })
            );
        } else {
            web3ModalConnect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [web3Modal]);

    // Wallet & Contract
    const [contractProvider, setContractProvider] = useState(null);

    // Swap
    const [currencyFrom, setCurrencyFrom] = useState("0.0");
    const [currencyTo, setCurrencyTo] = useState("0.0");

    // Functions
    const connect = async () => {
        try {
            const _provider = await web3Modal.connect();
            setProvider(_provider);
            return _provider;
        } catch (e) {
            return null;
        }
    };

    const createWeb3Provider = (_provider) => {
        const _web3Provider = new Web3Provider(_provider);
        setWeb3Provider(_web3Provider);
        return _web3Provider;
    };

    const createContractProvider = (_web3Provider, _address) => {
        const _contractProvider = new Contract(
            contractAddress,
            contract.abi,
            _web3Provider.getSigner(_address).connectUnchecked()
        );
        setContractProvider(_contractProvider);
        return _contractProvider;
    };

    const network = async (_web3Provider) => {
        const network = await _web3Provider.getNetwork();

        if (network.chainId !== 56) {
            notification({
                status: 0,
                message: swap.response.warning.invalidNetwork
            });
            return null;
        } else {
            return network;
        }
    };

    const address = (_provider) => {
        return _provider.selectedAddress ? _provider.selectedAddress : _provider.accounts[0];
    };

    const onConnect = async () => {
        // Connect to provider
        const _provider = await connect();
        if (_provider === null) return;

        // Subscribe to events
        listener(_provider);

        // Create web3 provider
        const _web3Provider = createWeb3Provider(_provider);

        // Validate network
        await network(_web3Provider);

        // Get address
        const _address = address(_provider);

        // Create contract
        const _contractProvider = createContractProvider(_web3Provider, _address);
        if (_contractProvider === null) return;

        // Successful connection
        notification({
            status: 1,
            message: swap.response.success.connected
        });
        setState(1);
    };

    const importAsset = async () => {
        try {
            provider.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20',
                    options: {
                        address: contractAddress,
                        symbol: _contractSymbol,
                        decimals: _contractDecimal,
                        image: _contractLogo
                    }
                }
            });
        } catch (e) { }
    };

    // Listener
    const listener = (_provider) => {
        if (!_provider.on) {
            return;
        }

        _provider.on("chainChanged", async (chainId) => {
            if (parseInt(chainId, 16) !== 56) {
                notification({
                    status: 0,
                    message: swap.response.warning.invalidNetwork
                });
            } else {
                notification({});
            }
            window.location.reload();
        });

        _provider.on("disconnect", async (error) => {
            notification({
                status: 0,
                message: swap.response.warning.disconnected
            });
        });
    };

    // State
    const stateType = () => {
        if (state === 0) return swap.button.connectWallet;
        if (state === 1) return swap.button.swap;
        if (state === 2) return <CircularProgress sx={{ color: "hsla(0, 0%, 100%, 0.5)" }} />;
    };

    const stateProvider = () => {
        if (state === 0) onConnect();
        if (state === 1) swapCurrency();
        return;
    };

    // Snackbar
    const [hasNotification, setHasNotification] = useState(false);
    const [response, setResponse] = useState({
        status: "success",
        message: "",
        link: ""
    });

    // Notification
    const notification = ({ status = -1, message = "", link = "" }) => {
        if (status !== -1 && message) {
            setResponse({
                status: _responseStatus[status],
                message: message,
                link: link
            });
            setHasNotification(true);
        } else {
            setHasNotification(false);
        }
    };

    // Notification Trigger
    const notificationClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setHasNotification(false);
    };

    // Exchange
    const calculateCurrency = async (currency) => {
        setCurrencyFrom(currency);
        try {
            let factor = BigNumber.from(10).pow(18);
            let priceFactor = BigNumber.from(10).pow(8);
            let decimalFactor = BigNumber.from(10).pow(2);
            let amountOfWei = parseEther(currency);
            let amountOfPair = amountOfWei.mul(factor);
    
            const priceUSD = BigNumber.from(await contractProvider.priceUSD());
            const pricePairUSD = BigNumber.from(await contractProvider.getLatestPrice());
            const amountUSD = amountOfPair.mul(pricePairUSD).div(priceFactor);
            const amountToSwap = amountUSD.mul(decimalFactor).div(priceUSD);
            setCurrencyTo(formatEther(amountToSwap.div(factor)));
        } catch (e) {}
    };

    const swapCurrency = async () => {
        const _network = await network(web3Provider);
        if (_network !== null) {
            try {
                const transaction = await contractProvider.swap({ value: parseEther(currencyFrom) });
                setState(2);
                const transactionReceipt = await transaction.wait();
                notification({
                    status: 1,
                    message: transactionReceipt.transactionHash,
                    link: `https://bscscan.com/tx/${transactionReceipt.transactionHash}`
                });
            } catch (e) {
                notification({
                    status: 2,
                    message: swap.response.error.unsuccessfulTransaction
                });
            }
            setState(1);
        }
    };

    return (
        <Web3Context.Provider value={{
            currencyFrom,
            currencyTo,
            setCurrencyFrom: (currency) => calculateCurrency(currency),
            stateType,
            stateProvider,
            importAsset
        }}>
            {props.children}
            <Snackbar open={hasNotification} autoHideDuration={6000} onClose={notificationClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
                <Alert
                    onClose={notificationClose}
                    severity={response.status}
                    sx={{ width: '100%' }}
                    className={classes.alert}
                    style={response.link ? { cursor: "pointer" } : {}}
                    onClick={() => {
                        if (response.link) {
                            window.open(response.link, "_blank");
                        }
                    }}
                >
                    {response.message}
                </Alert>
            </Snackbar>
        </Web3Context.Provider>
    );
};

export default Web3Wrapper;