import { Grid, Typography } from "@mui/material";

// Localization
import {
    clock
} from "../../utils/localization/localization_en.json";

export default function CountdownHolderCard({ ...date }) {
    const containerStyle = {
        position: "relative",
        padding: "0px 10px 0px 0px",
        borderRadius: 10
    }

    const boxStyle = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: 10,
        padding: "10px 10px",
        width: 60,
        height: 60,
        border: "2px solid hsla(0, 0%, 100%, 0.5)",
        borderRadius: 10
    }

    const labelStyle = {
        color: "#fff"
    }

    const NumberBox = ({ label, number }) => (
        <Grid item>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item style={boxStyle} className="countdown-bottom-page-number-holder-gradient">
                    <Typography variant="h5" gutterBottom component="div" style={{ margin: 0 }} >
                        {number}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" gutterBottom component="div" style={labelStyle}>
                        {label}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );

    return (
        <Grid item xs={12} style={{ padding: "5px 0px" }}>
            <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                style={containerStyle}
            >
                <NumberBox label={clock.days} number={date.days} />
                <NumberBox label={clock.hours} number={date.hours} />
                <NumberBox label={clock.minutes} number={date.minutes} />
                <NumberBox label={clock.seconds} number={date.seconds} />
            </Grid>
        </Grid>
    );
}