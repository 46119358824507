import { Container, Grid, Box } from "@mui/material";
import SectionCountdownLine from "./section_countdown_line";
import BlockTransparent from "../element/block_transparent";
import BlockButton from "../element/block_button";

// Localization
import {
    section,
    buy,
    whitepaper
} from "../../utils/localization/localization_en.json";

// Asset
import { CombineUi } from "../../utils/asset";

export default function SectionIntroSale() {
    const gridStyle = {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }

    const containerStyle = {
        zIndex: 2,
        paddingTop: "100px"
    }

    const imageGridStyle = {
        textAlign: "center"
    }

    const imageStyle = {
        width: "100%",
        maxWidth: 840,
        maxHeight: 480
    }

    return (
        <div id={"home"} style={gridStyle}>
            <canvas className="gradient-canvas" />
            <div className="gradient-canvas-mask" />
            <Container style={containerStyle} className="p-b-15-rem-lg">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={12} lg={5} md={5}>
                        <div style={{ margin: "0 auto", width: '100%', maxWidth: 520 }}>
                            <SectionCountdownLine />
                            <Box height={20} />
                            <BlockTransparent title={section["1"].title} subtitle={section["1"].subtitle} content={section["1"].content} />
                            <Box height={20} />
                            <Grid container justifyContent="flex-start" spacing={3}>
                                <Grid item>
                                    <BlockButton title={buy} onClick={() => window.location.replace("/invest")} />
                                </Grid>
                                <Grid item>
                                    <BlockButton title={whitepaper.label} onClick={() => window.open(whitepaper.url, "_blank")} />
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={7} md={7} style={imageGridStyle}>
                        <img src={CombineUi} alt={section["1"].title} className="responsive-image" style={imageStyle} />
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}