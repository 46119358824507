import { Container } from "react-bootstrap";
import { Grid } from "@mui/material";
import Block from "../element/block";
import StateCard from "../element/state_card";

// Icon
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ContactlessIcon from '@mui/icons-material/Contactless';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ChangeCircle from '@mui/icons-material/ChangeCircle';
import Savings from '@mui/icons-material/Savings';

// Localization
import {
    smartContract
} from "../../utils/localization/localization_en.json";

export default function SectionSmartContract({ title, subtitle, content }) {
    const containerStyle = {
        padding: "40px 0px"
    }

    const iconStyle = {
        width: 40,
        height: 40
    }

    return (
        <Container id={"contract"} style={containerStyle}>
            <Grid container alignItems="center" justifyContent="center">
                <Grid item md={6}>
                    <Block title={title} subtitle={subtitle} content={content} />
                </Grid>
                <Grid item md={6}>
                    <StateCard index={1} icon={<AllInclusiveIcon style={iconStyle} />} title={smartContract[0]['title']} content={smartContract[0]['content']} />
                    <StateCard index={2} icon={<CurrencyExchangeIcon style={iconStyle} />} title={smartContract[1]['title']} content={smartContract[1]['content']} />
                    <StateCard index={3} icon={<ContactlessIcon style={iconStyle} />} title={smartContract[2]['title']} content={smartContract[2]['content']} />
                    <StateCard index={4} icon={<AccountBalanceIcon style={iconStyle} />} title={smartContract[3]['title']} content={smartContract[3]['content']} />
                    <StateCard index={5} icon={<ChangeCircle style={iconStyle} />} title={smartContract[4]['title']} content={smartContract[4]['content']} />
                    <StateCard index={6} icon={<Savings style={iconStyle} />} title={smartContract[5]['title']} content={smartContract[5]['content']} />
                </Grid>
            </Grid>
        </Container>
    );
}