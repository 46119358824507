import { Container, Grid } from "@mui/material";
import DistributionCard from "../element/distribution_card";

// Localization
import {
    distribution
} from "../../utils/localization/localization_en.json";

export default function SectionDistribution() {
    const containerStyle = {}

    return (
        <Container style={containerStyle}>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                {distribution.map((value, index) => <DistributionCard key={index} title={value.title} percentage={value.percentage} />)}
            </Grid>
        </Container>
    );
}