import { useState } from "react";
import { Grid, TextField, Snackbar, Alert } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { client } from "../../api/client";
import BlockButton from "../element/block_button";

// Localization
import {
    subscribe,
    email,
    welcomeOnBoard,
    tryAgain
} from "../../utils/localization/localization_en.json";

// Style
const useStyles = makeStyles((theme) => ({
    field: {
        width: '100%',
        maxWidth: '300px',
        borderRadius: '10px',
        boxShadow: '0px 0px 10px 0px rgb(168 67 253 / 30%)',
        '& .MuiInputLabel-root': {
            color: 'rgba(255, 255, 255, 0.5)'
        },
        '& label.Mui': {
            color: 'rgba(255, 255, 255, 0.5)'
        },
        '& label.Mui-focused': {
            color: 'rgba(255, 255, 255, 0.5)'
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#846ff4'
        },
        '& .MuiOutlinedInput-root': {
            color: "#fff",
            '& fieldset': {
                borderColor: '#846ff4'
            },
            '&:hover fieldset': {
                borderColor: '#846ff4'
            },
            '&.Mui-focused fieldset': {
                borderColor: '#846ff4'
            }
        }
    },
    alert: {
        '& MuiAlert-message': {
            color: "#000"
        },
        '& MuiAlert-alert': {
            color: "#000"
        },
        '& div': {
            color: "#000"
        }
    }
}));

export default function SectionSubscribe() {
    const classes = useStyles();

    const [field, setField] = useState('');
    const [alert, setAlert] = useState(false);
    const [response, setResponse] = useState({
        status: "success",
        msg: welcomeOnBoard
    });

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setAlert(false);
    };

    const cardStyle = {
        width: "100%",
        padding: "10px 20px",
        borderBottom: "3px solid #846ff4",
        borderRadius: 10,
        backgroundColor: "hsla(0, 0%, 100%, 0.05)",
        boxShadow: "0px 5px 10px 0 rgb(168 67 253 / 30%)"
    }

    const itemStyle = {
        padding: 10
    }

    return (
        <Grid id="subscribe" container alignItems="center" justifyContent="center" style={cardStyle}>
            <Grid item xs style={itemStyle}>
                <TextField
                    type={"email"}
                    variant={"outlined"}
                    label={email}
                    name={"email"}
                    value={field}
                    onChange={e => setField(e.target.value)}
                    className={classes.field}
                />
            </Grid>
            <Grid item style={itemStyle}>
                <BlockButton title={subscribe} onClick={async () => {
                    try {
                        const { status } = await client.post("subscribe", { body: { email: field } });

                        if (status) {
                            setResponse({
                                status: "success",
                                msg: welcomeOnBoard
                            });
                        } else {
                            setResponse({
                                status: "warning",
                                msg: tryAgain
                            });
                        }
                    } catch (e) {
                        setResponse({
                            status: "warning",
                            msg: tryAgain
                        });
                    }
                    setAlert(true);
                }} />
                <Snackbar open={alert} autoHideDuration={6000} onClose={handleAlertClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
                    <Alert onClose={handleAlertClose} severity={response.status} sx={{ width: '100%' }} className={classes.alert}>
                        {response.msg}
                    </Alert>
                </Snackbar>
            </Grid>
        </Grid>
    );
}