import { Container, Grid, Box, Typography } from "@mui/material";
import SwapInput from "./swap_input";
import SwapButton from "./swap_button";
import SwapTextButton from "./swap_text_button";
import Web3Wrapper, { Web3Context } from "./web3_wrapper";

// Icon
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';

// Localization
import { swap } from "../../utils/localization/localization_en.json";

export default function SectionSwap() {
    const containerStyle = {
        zIndex: 2,
        paddingTop: "100px"
    }

    const cardStyle = {
        padding: "10px 20px",
        borderRadius: 15,
        backgroundColor: "rgba(3, 2, 59, 0.9)",
        boxShadow: "0px 5px 10px 0 rgb(122 122 122 / 30%)"
    }

    const iconStyle = {
        width: 40,
        height: 40
    }

    return (
        <Container style={containerStyle}>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Box sx={{ width: '100%', maxWidth: 500, textAlign: "center" }}>
                    <Web3Wrapper>
                        <Web3Context.Consumer>
                            {
                                ({
                                    currencyFrom,
                                    currencyTo,
                                    setCurrencyFrom,
                                    stateType,
                                    stateProvider,
                                    importAsset
                                }) => (
                                    <Grid container alignItems="center" justifyContent="center" style={cardStyle}>
                                        <Grid item xs={12}>
                                            <Typography variant="h4" gutterBottom component="div">
                                                {swap.label}
                                            </Typography>
                                        </Grid>
                                        <SwapInput name={"swap_a"} currency={"BNB"} value={currencyFrom} setValue={setCurrencyFrom} />
                                        <Grid item xs={12}>
                                            <KeyboardArrowDown style={iconStyle} />
                                        </Grid>
                                        <SwapInput name={"swap_b"} currency={"WB"} readOnly={true} value={currencyTo} />
                                        <SwapButton title={stateType()} onClick={() => stateProvider()} />
                                        <SwapTextButton title={swap.button.importToken} onClick={() => importAsset()} />
                                    </Grid>
                                )
                            }
                        </Web3Context.Consumer>
                    </Web3Wrapper>
                </Box>
            </Grid>
        </Container>
    );
}