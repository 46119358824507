import { Grid, Box, Typography } from "@mui/material";
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import ModelTrainingOutlinedIcon from '@mui/icons-material/ModelTrainingOutlined';
import ArchitectureIcon from '@mui/icons-material/Architecture';

// Localization
import {
    featureStatus
} from "../../utils/localization/localization_en.json";

export default function FeatureCard({ title, status, content }) {
    const boxStyle = {
        padding: "10px 20px",
        borderBottom: "3px solid #846ff4",
        borderRadius: 10,
        backgroundColor: "hsla(0, 0%, 100%, 0.05)",
        boxShadow: "0px 5px 10px 0 rgb(168 67 253 / 30%)"
    }

    const iconContainerStyle = {
        textAlign: "center",
        paddingBottom: 20
    }

    const iconStyle = {
        margin: 0,
        width: 50,
        height: 50,
        color: "#846ff4"
    }

    const titleStyle = {
        textAlign: "center"
    }

    const statusStyle = {
        textAlign: "center"
    }

    const statusStyles = {
        0: {
            color: "#846ff4"
        },
        1: {
            color: "#fad961"
        },
        2: {
            color: "#17ead9"
        }
    }

    const contentStyle = {
        color: "hsla(0, 0%, 100%, .7)"
    }

    const icon = (status) => {
        if (status === 0) return <ArchitectureIcon style={iconStyle} />
        if (status === 1) return <ModelTrainingOutlinedIcon style={iconStyle} />
        if (status === 2) return <TaskOutlinedIcon style={iconStyle} />
    }

    return (
        <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box sx={{ width: '100%', minHeight: 300, maxHeight: 300 }} style={boxStyle} data-aos="fade-up" data-aos-anchor-placement="top-center">
                <Box style={iconContainerStyle}>
                    {icon(status)}
                </Box>
                <Typography variant="h5" gutterBottom component="div" style={titleStyle}>
                    {title}
                </Typography>
                <Typography variant="h6" gutterBottom component="div" style={{ ...statusStyle, ...statusStyles[status] }}>
                    {featureStatus[status]}
                </Typography>
                <Typography variant="body1" gutterBottom component="div" style={contentStyle}>
                    {content}
                </Typography>
            </Box>
        </Grid>
    );
}