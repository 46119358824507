import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
    createSelector
} from "@reduxjs/toolkit";

import { requestStatus } from "../../utils/request_status";

// Localization
import { roadmap } from "../../utils/localization/localization_en.json";

const stageAdapter = createEntityAdapter({
    selectId: (stage) => {
        const { day, month, year } = stage;
        let date = new Date(`${month}/${day}/${year}, 12:00:00`);
        return date.toString();
    },
    sortComparer: (a, b) => {
        let aDate = new Date(a.year, a.month, a.day);
        let bDate = new Date(b.year, b.month, b.day);

        if (aDate < bDate) {
            return -1;
        } else if (aDate === bDate) {
            return 0;
        } else {
            return 1;
        }
    }
});

const initialState = stageAdapter.getInitialState({
    status: requestStatus.IDLE,
    error: null
});

export const fetchStages = createAsyncThunk("/stage/fetch", async () => roadmap.filter(item => item.planned));

const stageSlice = createSlice({
    name: "stage",
    initialState: initialState,
    reducers: {},
    extraReducers: {
        // Fetch
        [fetchStages.pending]: (state) => {
            state.status = requestStatus.LOADING;
        },
        [fetchStages.fulfilled]: (state, action) => {
            state.status = requestStatus.SUCCEEDED;
            stageAdapter.upsertMany(state, action.payload);
        },
        [fetchStages.rejected]: (state, action) => {
            state.status = requestStatus.FAILED;
            state.error = action.payload;
        },
    },
});

export const { stageUpdated } = stageSlice.actions;

export default stageSlice;

export const {
    selectAll: selectAllStage,
    selectById: selectStageById,
    selectIds: selectStageIds,
} = stageAdapter.getSelectors((state) => state.stage);

export const selectStage = createSelector(
    [selectStageIds, (state) => state],
    (stages, state) => {
        return _binarySearchStage(stages);
    }
);

function _binarySearchStage(stages) {
    let today = Date.now();
    let start = 0;
    let end = stages.length - 1;

    let middle;
    let middleDate;

    if (today < new Date(stages[start])) {
        return start;
    }

    if (today >= new Date(stages[end])) {
        return -1;
    }

    while (start <= end) {
        middle = Math.floor((start + end) / 2);
        middleDate = new Date(stages[middle]);

        if (start === end) {
            return middle;
        } else if (middleDate <= today) {
            start = middle + 1;
        } else {
            end = middle;
        }
    }

    return -1;
}