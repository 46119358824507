import { Container, Grid } from "@mui/material";
import CheckListCard from "../element/check_list_card";
import ImageCard from "../element/image_card";

export default function SectionCheckList({ img, title, data }) {
    const containerStyle = {
        padding: 0
    }

    return (
        <Container style={containerStyle}>
            <Grid container alignItems="center" justifyContent="center">
                <Grid item md={6}>
                    {
                        data.map((value, index) => {
                            return <CheckListCard key={`checkList_${index}`} title={value} />
                        })
                    }
                </Grid>
                <ImageCard src={img} alt={title} />
            </Grid>
        </Container>
    );
}