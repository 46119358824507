import { Grid, Input } from "@mui/material";

export default function SwapInput({ name, currency, value, setValue, ...props }) {
    const containerStyle = {
        margin: "10px 0px",
        padding: "15px 20px",
        borderRadius: 15,
        backgroundColor: "hsla(0, 0%, 100%, 0.3)",
    }

    const fieldStyle = {
        width: '100%',
        '&:before': {
            border: "0 !important"
        },
        '&:after': {
            border: 0
        },
        '& .MuiInputBase-input.MuiInput-input': {
            color: '#fff',
            fontSize: '26px'
        }
    };

    const pattern = "^[0-9]*[.,]?[0-9]*$";
    const regex = new RegExp(pattern);

    return (
        <Grid item container alignItems="center" justifyContent="center" xs={12} style={containerStyle}>
            <Grid item xs>
                <Input
                    type="text"
                    name={name}
                    placeholder="0.0"
                    value={value}
                    onChange={e => {
                        if (regex.test(e.target.value)) {
                            setValue(e.target.value);
                        }
                    }}
                    sx={fieldStyle}
                    {...props}
                />
            </Grid>
            <Grid item style={{ padding: 10 }}>
                {currency}
            </Grid>
        </Grid>
    );
}