import { Grid, Box, Typography } from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Done from '@mui/icons-material/Done';

export default function FeatureShortCard({ state, title }) {
    const containerStyle = {
        position: "relative",
        padding: "5px 10px",
        borderRadius: 10,
        background: "hsla(0, 0%, 100%, 0.05)"
    }

    const itemStyle = {
        padding: 10
    }

    const iconContainerStyle = {
        padding: 10,
        borderRadius: 10
    }

    const iconStyle = {
        width: 40,
        height: 40
    }

    return (
        <Grid item xs={12} style={{ padding: "10px 0px" }}>
            <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="start"
                style={containerStyle}
            >
                <Grid item className="feature-gradient" style={{...itemStyle, ...iconContainerStyle}}>
                    { state ? <Done style={iconStyle} /> : <LockOutlinedIcon style={iconStyle} /> }
                </Grid>
                <Grid item xs style={itemStyle}>
                    <Box style={{ width: "100%" }}>
                        <Typography variant="h5" gutterBottom component="div" style={{ margin: 0 }}>
                            {title}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
}