import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// Localization
import {
    warrantybox,
    navigation,
    buy,
    twitterUrl,
    telegramUrl,
} from "../../utils/localization/localization_en.json";

// Asset
import { Logo, LogoWhite } from "../../utils/asset";

// Style
const useStyles = makeStyles({
    buttonActive: {
        color: "#fff!important",
        backgroundImage: "linear-gradient(90deg, #4ebeec, #7450fe)!important",
        "&:hover": {
            borderColor: "#4ebeec!important",
        },
        "&:disabled": {
            color: "#000!important",
            backgroundImage: "linear-gradient(90deg, #d5d5d5, #e7e7e7)!important",
        },
    },
    buttonUnactive: {
        color: "#fff!important",
        backgroundImage: "transparent!important",
        "&:hover": {
            borderColor: "#4ebeec!important",
        },
        "&:disabled": {
            color: "#fff!important",
            backgroundImage: "transparent!important",
        },
    },
});

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Header() {
    // Styles
    const navbarBackgroundTransparent = {
        background: "transparent",
    };

    const navbarBackgroundColored = {
        background: "hsl(241deg 93% 12% / 90%)",
        borderBottom: "3px solid rgb(168, 67, 253)",
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        boxShadow: "rgb(168 67 253 / 30%) 4px 4px 10px 0px",
    };

    const navbarButton = {
        fontSize: "16px",
        padding: "5px 30px",
        borderBottom: "3px solid #846ff4",
        borderRadius: 10,
        boxShadow: "0px 5px 10px 0 rgb(168 67 253 / 30%)",
        transition: "all .8s",
    };

    // Route
    const { pathname } = useLocation();
    const query = useQuery();

    // Style
    const classes = useStyles();

    // Scroll status
    const [navbar, setNavbar] = useState(false);
    const [toggle, setToggle] = useState(false);

    // Handle scroll function
    const changeBackground = () => {
        if (window.scrollY >= 66) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };

    // Handle redirection
    const followHref = (path, target) => {
        if (pathname === path) {
            scrollToTargetAdjusted(target);
        } else {
            if (target) {
                window.location.replace(`${path}?target=${target}`);
            } else {
                window.location.replace(`${path}`);
            }
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", changeBackground);

        const target = query.get("target");

        if (target) {
            let timer = setTimeout(() => {
                scrollToTargetAdjusted(target);
                clearTimeout(timer);
            }, 2000);
        }

        return () => {
            window.removeEventListener("scroll", changeBackground);
        };
    }, [query]);

    function openInNewTab(url) {
        window.open(url, "_blank").focus();
    }

    function scrollToTargetAdjusted(id) {
        try {
            let elementOffset = document.getElementById(id).offsetTop;
            let headerOffset = document.getElementById("header").clientHeight;
            let offsetPosition = elementOffset - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth",
            });
        } catch (e) { }
    }

    return (
        <Navbar
            id={"header"}
            collapseOnSelect
            fixed="top"
            expand="xl"
            variant="dark"
            style={navbar ? navbarBackgroundColored : navbarBackgroundTransparent}
        >
            <Container>
                <Navbar.Brand onClick={() => followHref("/", "home")}>
                    <img
                        src={navbar ? Logo : LogoWhite}
                        alt={warrantybox}
                        style={{ width: 50 }}
                    />
                </Navbar.Brand>
                <Navbar.Toggle onClick={() => setToggle(!toggle)} style={navbar ? { opacity: 1 } : { opacity: 0 }} disabled={!navbar} />
                <Navbar.Collapse in={navbar ? toggle : false}>
                    <Nav className="me-auto">
                        <Nav.Link onClick={() => followHref("/", "home")}>
                            {navigation.home}
                        </Nav.Link>
                        <Nav.Link onClick={() => followHref("/", "concept")}>
                            {navigation.concept}
                        </Nav.Link>
                        <NavDropdown title={navigation.use.title} id={"use-dropdown"}>
                            <NavDropdown.Item onClick={() => followHref("/", "mission")}>
                                {navigation.use.routes.mission}
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => followHref("/", "purpose")}>
                                {navigation.use.routes.purpose}
                            </NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown
                            title={navigation.products.title}
                            id={"products-dropdown"}
                        >
                            <NavDropdown.Item
                                onClick={() => followHref("/products", "individual")}
                            >
                                {navigation.products.routes.individual}
                            </NavDropdown.Item>
                            <NavDropdown.Item
                                onClick={() => followHref("/products", "enterprise")}
                            >
                                {navigation.products.routes.enterprise}
                            </NavDropdown.Item>
                            <NavDropdown.Item
                                onClick={() => followHref("/products", "features")}
                            >
                                {navigation.products.routes.features}
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => followHref("/products", "trusted")}>
                                {navigation.products.routes.trusted}
                            </NavDropdown.Item>
                            <NavDropdown.Item
                                onClick={() => followHref("/products", "benefits")}
                            >
                                {navigation.products.routes.benefits}
                            </NavDropdown.Item>
                            <NavDropdown.Item
                                onClick={() => followHref("/products", "presentation")}
                            >
                                {navigation.products.routes.presentation}
                            </NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown
                            title={navigation.economics.title}
                            id={"economics-dropdown"}
                        >
                            <NavDropdown.Item onClick={() => followHref("/", "token")}>
                                {navigation.economics.routes.token}
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => followHref("/", "contract")}>
                                {navigation.economics.routes.contract}
                            </NavDropdown.Item>
                            {/* <NavDropdown.Item onClick={() => followHref("/", "rating")}> */}
                            {/* {navigation.economics.routes.rating} */}
                            {/* </NavDropdown.Item> */}
                            <NavDropdown.Item onClick={() => followHref("/", "tokenomics")}>
                                {navigation.economics.routes.tokenomics}
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => followHref("/", "distribution")}>
                                {navigation.economics.routes.distribution}
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => followHref("/", "prices")}>
                                {navigation.economics.routes.prices}
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => followHref("/", "tax")}>
                                {navigation.economics.routes.tax}
                            </NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link onClick={() => followHref("/", "whitepaper")}>
                            {navigation.whitepaper}
                        </Nav.Link>
                        <Nav.Link onClick={() => followHref("/", "roadmap")}>
                            {navigation.roadmap}
                        </Nav.Link>
                        <NavDropdown
                            title={navigation.campaigns.title}
                            id={"campaigns-dropdown"}
                        >
                            <NavDropdown.Item onClick={() => followHref("/campaigns")}>
                                {navigation.campaigns.routes.rewards}
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => followHref("/campaigns")}>
                                {navigation.campaigns.routes.environmental}
                            </NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown
                            title={navigation.community.title}
                            id={"community-dropdown"}
                        >
                            <NavDropdown.Item onClick={() => openInNewTab(twitterUrl)}>
                                {navigation.community.routes.twitter}
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => openInNewTab(telegramUrl)}>
                                {navigation.community.routes.telegram}
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => followHref(pathname !== "/products" ? "/" : "/products", "subscribe")}>
                                {navigation.community.routes.subscribe}
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    <Nav>
                        <Button
                            variant="text"
                            className={navbar ? classes.buttonActive : classes.buttonUnactive}
                            style={navbarButton}
                            onClick={() => followHref("/invest")}
                        >
                            {buy}
                            <ArrowForwardIosIcon style={{ width: "20px", height: "20px" }} />
                        </Button>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
